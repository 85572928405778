<template>
  <b-container>
    <b-row>
      <b-col cols="8">
        <b-card>
          <b-row>
            <b-col cols="12" class="text-left mb-2">
              <b-badge :variant="statusVariant(form.status)">
                <span class="text-nowrap" v-if="form.status === 1">{{
                  form.status_name
                }}</span>
                <span class="text-nowrap" v-else-if="form.status === 2">{{
                  form.status_name
                }}</span>
                <span class="text-nowrap" v-else-if="form.status === 3">{{
                  form.status_name
                }}</span>
                <span class="text-nowrap" v-else-if="form.status === 4">{{
                  form.status_name
                }}</span>
              </b-badge>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col cols="4"> Code </b-col>
                <b-col
                  cols="8"
                  class="text-right font-weight-bolder"
                  v-if="form.code === ''"
                >
                  -
                </b-col>
                <b-col cols="8" class="text-right font-weight-bolder" v-else>
                  {{ form.code }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col cols="4"> Warehouse </b-col>
                <b-col cols="8" class="text-right font-weight-bolder">
                  {{ form.warehouse_name }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col cols="4"> Datetime </b-col>
                <b-col cols="8" class="text-right font-weight-bolder">
                  {{ moment(form.date) }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col cols="4"> Vendor </b-col>
                <b-col cols="8" class="text-right font-weight-bolder">
                  <span
                    v-for="(vendor, index) in informations.vendor"
                    :key="index"
                    ><span v-if="index > 0">, </span>{{ vendor.name }}
                  </span>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col cols="4"> Category </b-col>
                <b-col cols="8" class="text-right font-weight-bolder">
                  <span
                    v-for="(category, index) in informations.category"
                    :key="index"
                  >
                    <span v-if="index > 0">, </span>{{ category.name }}
                  </span>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col cols="4"> Description </b-col>
                <b-col
                  cols="8"
                  class="text-right font-weight-bolder"
                  v-if="form.description !== ''"
                >
                  {{ form.description }}
                </b-col>
                <b-col md="8" class="text-right font-weight-bolder" v-else>
                  -
                </b-col>
              </b-row>
              <hr v-if="form.status === 3" />
              <b-row v-if="form.status === 3">
                <b-col cols="4"> Reject Reason </b-col>
                <b-col
                  cols="8"
                  class="text-right font-weight-bolder"
                  v-if="reject_reason !== ''"
                >
                  {{ reject_reason }}
                </b-col>
                <b-col cols="8" class="text-right font-weight-bolder" v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-row v-if="form.status === 1" class="mb-1">
          <b-col v-if="permissions.includes('warehouse-stockopname-confirm')">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              block
              @click="confirm()"
            >
              Confirm
            </b-button>
          </b-col>
          <b-col v-if="permissions.includes('warehouse-stockopname-reject')">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="danger"
              class="mr-1"
              block
              v-b-modal.modal-reject
            >
              Reject
            </b-button>
          </b-col>
          <!-- modal reject -->
          <b-modal
            id="modal-reject"
            title="Reject Form"
            ok-title="Reject"
            cancel-variant="outline-secondary"
            hide-footer
          >
            <validation-observer ref="loginValidation">
              <b-form ref="form" @submit.prevent>
                <b-form-group
                  :state="errors"
                  label="Reject Reason"
                  label-for="reject"
                  invalid-feedback="Reject Reason required"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="reject"
                    rules="required"
                  >
                    <b-form-input
                      id="reject"
                      name="reject"
                      type="text"
                      v-model="reject_reason"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Reject Reason"
                      required="required"
                    />
                    <small class="text-danger">{{
                      errors[0] || errMessage
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <b-row>
                  <b-col>
                    <b-button
                      class="mt-3"
                      type="submit"
                      variant="danger"
                      block
                      @click="reject()"
                    >
                      Reject
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
          <!-- end modal reject -->
        </b-row>
        <b-row
          v-if="
            permissions.includes('warehouse-stockopname-update') &&
            form.status === 2
          "
        >
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              block
              @click="finish()"
            >
              Finish
            </b-button>
          </b-col>
        </b-row>
        <b-row
          v-if="
            permissions.includes('warehouse-stockopname-delete') &&
            form.status === 1
          "
        >
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="danger"
              class="mr-1"
              block
              @click="deleteStockOpname()"
            >
              Delete
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4">
        <b-card>
          <label for="history" class="text-primary font-weight-bolder mb-1"
            >Employees</label
          >
          <div v-for="(worker, index) in employees.worker" :key="index">
            <hr v-if="index > 0" />
            <p class="font-weight-bolder">
              {{ worker.name }} -
              <span
                v-if="worker.name.includes(employees.pic)"
                class="text-primary"
                >(PIC)</span
              >
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BImg,
  BContainer,
  BBadge,
  BFormTextarea,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    ToastificationContent,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BContainer,
    BBadge,
    BFormTextarea,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        status: null,
        status_name: "",
        code: "",
        description: "",
        date: null,
        warehouse_name: "",
      },
      employees: {
        worker: [],
        total_worker: null,
        pic: "",
      },
      informations: {
        category: [],
        total_category: null,
        vendor: [],
        total_vendor: null,
      },
      permissions: [],
      reject_reason: "",
      errors: "",
      errMessage: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        1: "light-warning",
        2: "light-primary",
        3: "light-danger",
        4: "light-success",
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getAllPermissions();
    this.getDetails();
  },
  methods: {
    moment(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getDetails() {
      const id = this.$route.params.id;
      this.$http.get("warehouse/stock-opname/" + id).then((response) => {
        console.log(response.data.data);
        this.form.status = response.data.data.status;
        this.form.status_name = response.data.data.status_name;
        this.form.code = response.data.data.code;
        this.form.warehouse_name = response.data.data.warehouse_name;
        this.form.description = response.data.data.description;
        this.form.date = response.data.data.date;
        this.form.reject_reason = response.data.data.reject_reason;

        var i;
        var $employees = response.data.data.worker;
        this.employees.total_worker = response.data.data.worker.length;
        for (i = 0; i < $employees.length; i++) {
          var $data = {
            id: $employees[i].id,
            name: $employees[i].name,
          };
          this.employees.worker.push($data);
        }
        this.employees.pic = response.data.data.pic.name;

        var $categories = response.data.data.category;
        this.informations.total_category = response.data.data.category.length;
        for (i = 0; i < $categories.length; i++) {
          var $data = {
            id: $categories[i].id,
            name: $categories[i].name,
          };
          this.informations.category.push($data);
        }

        var $vendors = response.data.data.vendor;
        this.informations.total_vendor = response.data.data.vendor.length;
        for (i = 0; i < $vendors.length; i++) {
          var $data = {
            id: $vendors[i].id,
            name: $vendors[i].name,
          };
          this.informations.vendor.push($data);
        }
      });
    },
    confirm() {
      const id = this.$route.params.id;
      this.$http
        .post("warehouse/stock-opname/" + id + "/confirm")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Stock Opname Confirmed",
              variant: "success",
            },
          });
          location.href = "/warehouse/stock-opname";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    reject() {
      const id = this.$route.params.id;
      this.$http
        .post("warehouse/stock-opname/" + id + "/reject", {
          reject_reason: this.reject_reason,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Stock Opname Rejected",
              variant: "danger",
            },
          });
          location.href = "/warehouse/stock-opname";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    finish() {
      const id = this.$route.params.id;
      this.$http
        .post("warehouse/stock-opname/" + id + "/finish")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Stock Opname Finished",
              variant: "success",
            },
          });
          location.href = "/warehouse/stock-opname";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    deleteStockOpname() {
      const id = this.$route.params.id;
      this.$http
        .post("warehouse/stock-opname/" + id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Stock Opname has been Deleted",
              variant: "danger",
            },
          });
          location.href = "/warehouse/stock-opname";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>